import SanityImage from 'gatsby-plugin-sanity-image'
import React from 'react'

import { RichTextMessage } from '../../ui-kit/RichTextMessage'
import { RichTextTitle } from '../../ui-kit/RichTextTitle'
import { calcRem } from '../../styles/utils'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SliderWrapper from './_SlickSliderStyle'

const corporateHeaderStyle = {
  maxWidth: calcRem(1920),
}

export function StepsSection({ title, steps }) {
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    adaptiveHeight: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className='ft-slick__dots--custom'>
        <div className='loading' />
      </div>
    ),
  }

  return (
    <div className='mx-auto mt-28 px-8 lg:px-32' style={corporateHeaderStyle}>
      <RichTextTitle richText={title} className='text-center' />
      <SliderWrapper className='mt-16'>
        <Slider {...settings}>
          {steps.map((step) => (
            <Step {...step} />
          ))}
        </Slider>
      </SliderWrapper>
    </div>
  )
}

function Step({ title, label, description, image }) {
  return (
    <div className='mx-auto w-fit flex-shrink-0 flex flex-col items-center lg:flex-row py-10'>
      <SanityImage
        {...image}
        alt={title}
        loading='lazy'
        style={{
          aspectRatio: image.asset.metadata.dimensions.aspectRatio,
        }}
        className='w-3/4 lg:w-auto lg:h-[25rem]'
      />
      <div className='lg:ml-12 my-auto text-center lg:text-left space-y-4 mt-4'>
        <span className='text-[#00CCAD] text-xl lg:text-3xl tracking-tight'>
          {label}
        </span>
        <h3 className='max-w-xl font-poppins font-black text-black text-2xl'>
          {title}
        </h3>
        <RichTextMessage
          richText={description}
          className='font-light text-lg lg:w-96'
        />
      </div>
    </div>
  )
}
