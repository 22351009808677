import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import classNames from 'classnames';

import { Check } from '../../svgs/check';
import { RichTextTitle } from '../../ui-kit/RichTextTitle';

export function BulletedListSection({ title, items, image, imagePosition }) {
  return (
    <div className='container mt-16 mx-auto px-8 sm:w-5/6 md:w-full md:grid md:grid-cols-2 md:gap-x-16 md:mt-28'>
      <div
        className={classNames('flex justify-center items-center', {
          'md:order-2': imagePosition === 'right',
        })}
      >
        <SanityImage
          {...image}
          style={{
            width: '100%',
            aspectRatio: image.asset.metadata.dimensions.aspectRatio,
          }}
          loading="lazy"
          alt={title}
          className='2xl:w-3/4'
        />
      </div>
      <div>
        <RichTextTitle
          richText={title}
          className='mt-7 md:mt-0 text-center md:text-left'
        />
        {items.map((item) => (
          <div
            key={item}
            className='mt-7 flex justify-start text-xs md:mt-12 lg:text-lg lg:leading-extra-loose'
          >
            <div className='mt-2 ml-2 mr-8'>
              <Check className='text-green' />
            </div>
            <div>{item}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
