import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import PortableText from 'react-portable-text';
import createImageUrlBuilder from '@sanity/image-url';

const CallToAction = ({ title, link, buttonText, description }) => (
  <section className="w-full">
    <div className="w-full px-0">
      <div
        className="lg:py-14 lg:px-20 p-10 rounded-2xl bg-kimbo-gradient flex items-center justify-between flex-col lg:flex-row"
      >
        <div className="block text-center mb-5 lg:text-left lg:mb-0">
          <h2
            className="font-manrope text-4xl text-white font-semibold mb-5 lg:mb-2"
          >
            {title}
          </h2>
          <div className="text-lg text-indigo-50">
            {description}
          </div>
        </div>
        <a
          href={link}
          target='_blank'
          className="flex items-center gap-2 bg-white rounded-full shadow-sm text-lg text-green font-semibold py-4 px-8 transition-all duration-500"
        >{buttonText}
          <svg
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 7L16.4167 7M11.8333 12.5L16.6852 7.64818C16.9907 7.34263 17.1435 7.18985 17.1435 7C17.1435 6.81015 16.9907 6.65737 16.6852 6.35182L11.8333 1.5"
              stroke="#4F46E5"
              strokeWidth="2.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </div>
    </div>
  </section>

);


export function RichTextMessage({
  className,
  richText,
  usePortableText = false,
}) {
  const serializers = {
    image: (props) => {
      return (
        <div className='flex justify-center items-center w-full rounded-md'>
          <img
            className='rounded-md'
            src={createImageUrlBuilder({
              dataset: 'production',
              projectId: 'zngik94t',
            })
              .image(props)
              .url()}
            alt='Descriptive text about the img'
          />
        </div>
      );
    },
    h1: (props) => <h1 className='text-4xl font-bold text-black' {...props} />,
    h2: (props) => <h2 className='text-3xl font-bold  text-black' {...props} />,
    h3: (props) => <h3 className='text-2xl font-bold  text-black' {...props} />,
    h4: (props) => <h4 className='text-xl font-bold  text-black' {...props} />,
    h5: (props) => <h5 className='text-lg font-bold  text-black' {...props} />,
    h6: (props) => <h6 className='text-base font-bold  text-black' {...props} />,
    ul: (props) => <ul className='list-disc ml-6 my-4' {...props} />,
    ol: (props) => <ol className='list-decimal ml-6 my-4' {...props} />,
    li: (props) => <li className='my-2 text-lg text-black' {...props} />,
    codeField: (props) => <div dangerouslySetInnerHTML={{ __html: props.code }} />,
    articleCallToAction: (props) => <CallToAction {...props} />
  };

  return usePortableText ? (
    <PortableText
      projectId='zngik94t'
      dataset='production'
      content={richText}
      serializers={serializers}
    />
  ) : (
    <BlockContent
      className={className}
      blocks={richText}
      renderContainerOnSingleChild
    />
  );
}
