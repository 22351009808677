import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

const RequestDemoDialog = ({ setIsOpen, cancelButtonRef }) => {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}
      >
        <div
          className='relative pl-4 pt-4 z-30 cursor-pointer'
          onClick={() => setIsOpen(false)}
        >
          &#10060;
        </div>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black opacity-50 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='absolute inline-block bg-white w-full lg:w-2/3 xl:w-4/5 top-16 lg:top-0 xl:top-0 right-0 bottom-0 rounded-t-lg lg:rounded-none md:rounded-none xl:rounded-none text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle  modal-wrapper'>
              <iframe
                src='https://outlook.office365.com/owa/calendar/KimboCareRequestaDemo@kimbocare.com/bookings/'
                width='100%'
                height='100%'
                style={{ border: 0, overflow: 'auto' }}
                title='KimboCare Request a Demo Calendar'
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default RequestDemoDialog
