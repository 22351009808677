import React from 'react'
import { RichTextMessage } from '../../../ui-kit/RichTextMessage'
import useDeviceDetect from '../../../utils/useDeviceDetect'
import './HelpSection.css'


const HelpSection = ({ title, description, questionsAnswers }) => {
    const { isMobile } = useDeviceDetect()
    return (
        <div className="w-full pt-12 pb-16 bg-white">
            <div className="w-11/12 md:w-10/12 lg:w-8/12 mx-auto pb-8">
                <h1 className="w-full py-2 lg:py-4 mb-2 lg:mb-4 text-left lg:text-center font-poppins font-black text-3xl md:text-4xl lg:text-10xl lg:leading-10" style={helpPageTitle}>{title}</h1>
                <RichTextMessage className="text-lg font-proxima-nova py-12" richText={description} />
            </div>
            {
                isMobile
                    ?
                    <HelpSectionMobileDisplay faQs={questionsAnswers} />
                    :
                    <HelpSectionDesktopDisplay faQs={questionsAnswers} />
            }
        </div>
    )
}

class HelpSectionDesktopDisplay extends React.Component {

    componentDidMount() {
        this.qaHeight = document.getElementById("faq-wrapper").clientHeight
    }

    ajustAnsBlockHeight(answerId) {
        let ansBHeight = document.getElementById(answerId).clientHeight

        if (ansBHeight > this.qaHeight) {
            document.getElementById("faq-wrapper").style.height = ansBHeight + "px"
        }
        else
            document.getElementById("faq-wrapper").style.height = "auto"
    }

    changeContent(event) {
        let questionId = event.target.getAttribute("id")
        let answerId = questionId.replace('q', 'a')
        document.querySelectorAll(".question").forEach((question) => {
            if (question.classList.contains("activeQuestion")) {
                question.classList.remove("activeQuestion");
            }
        })
        document.getElementById(questionId).classList.add("activeQuestion")
        document.querySelectorAll(".answer").forEach((question) => {
            if (question.classList.contains("activeAnswer")) {
                question.classList.remove("activeAnswer");
            }
            document.getElementById(answerId).classList.add("activeAnswer")
        })
        this.ajustAnsBlockHeight(answerId)
        if (window) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }


    render() {
        return (
            <div id="faq-wrapper" className="w-11/12 lg:w-9/12 mx-auto relative">
                <ul>
                    {this.props.faQs.map((qa, index) => (
                        <li id='faq-list' className="flex flex-row justify-between gap-16" key={qa}>
                            <div id={`q_${index}`} className={index == 0 ? `${questionClassNames} activeQuestion` : questionClassNames} style={helpQuestionBlock} onClick={event => this.changeContent(event)}>
                                {qa.question}
                            </div>
                            <div id={`a_${index}`} className={index == 0 ? `${answerClassNames} activeAnswer` : answerClassNames} style={helpAnswerBlock}>
                                <h2 className="text-xl uppercase py-8">{qa.question}</h2>
                                <RichTextMessage
                                    richText={qa.answer}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}



const HelpSectionMobileDisplay = ({ faQs }) => {
    return (
        <div className="w-11/12 lg:w-9/12 mx-auto">
            <ul>
                {faQs.map((qa, index) => (
                    <li className="flex flex-col justify-between">
                        <div id={`q_${index}`} className={index == 0 ? `${questionMobileClassNames} activeMobileQuestion` : questionMobileClassNames} style={helpQuestionMobileBlock} onClick={event => expand(event)}>
                            {qa.question}
                        </div>
                        <div id={`a_${index}`} className={index == 0 ? `${answerMobileClassNames} activeMobileAnswer` : answerMobileClassNames} style={helpAnswerMobileBlock}>
                            <RichTextMessage
                                richText={qa.answer}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}


function expand(event) {
    let questionId = event.target.getAttribute("id")
    let answerId = questionId.replace('q', 'a')
    document.querySelectorAll(".question").forEach((question) => {
        if (question.classList.contains("activeMobileQuestion")) {
            question.classList.remove("activeMobileQuestion");
        }
    })
    document.getElementById(questionId).classList.add("activeMobileQuestion")
    document.querySelectorAll(".answer").forEach((question) => {
        if (question.classList.contains("activeMobileAnswer")) {
            question.classList.remove("activeMobileAnswer");
        }
        document.getElementById(answerId).classList.add("activeMobileAnswer")
    })
}

const questionClassNames = "w-3/12 py-8 cursor-pointer transition question  "
const answerClassNames = "w-8/12 rounded-md p-12 absolute answer top-0 right-0 invisible answer"

const questionMobileClassNames = "w-full py-8 px-4 cursor-pointer transition question border rounded-lg  "
const answerMobileClassNames = "w-full px-4 answer h-0 truncate transition invisible my-4 answer"

const helpPageTitle = {
    color: " #2F2D2D",
    textTransform: " ",
    lineHeight: "54px"
}

const helpAnswerBlock = {
    boxShadow: "0px 20px 20px #7682B72E",
    font: "normal normal normal 16px/30px Poppins",
    color: "#020102",
}

const helpQuestionBlock = {
    font: "normal normal bold 20px/29px Poppins",
    color: "#020102",
    borderBottom: "1px solid rgba(220,220,220)"
}

const helpQuestionMobileBlock = {
    font: "normal normal bold 20px/29px Poppins",
    color: "#020102",
    border: "1px solid rgba(220,220,220)"
}

const helpAnswerMobileBlock = {
    font: "normal normal normal 16px/30px Poppins",
    color: " #020102",

}

export default HelpSection