import React from 'react';
import { RichTextMessage } from '../../../ui-kit/RichTextMessage';
import {
  RichTextTitle,
  RichTextTitleCustom,
  RichTextTitleMini,
} from '../../../ui-kit/RichTextTitle';

const AboutUsheaderSection = ({
  banner,
  ourMissionTitle,
  ourMissiondescription,
  ourVisionTitle,
  title,
}) => {
  return (
    <div className='w-full bg-white relative z-0' style={ourVisionBannerStyle}>
      <div
        className='w-full bg-white relative aboutusImageBanner z-0'
        style={ourVisionBannerStyle}
      >
        {/* <SanityImage className="w-full h-full object-cover" {...banner} alt={title} /> */}
      </div>
      <div className='w-full h-fit bg-transparent absolute -bottom-2 xl:-bottom-12'>
        <div className='w-5/6 mx-auto pb-16 lg:pb-20 sm:pb-16 md:pb-16'>
          <RichTextTitleMini
            className='text-green py-4 text-xl'
            richText={title}
          />
          <RichTextTitleCustom
            className='font-white w-full sm:w-8/12 xl:w-8/12 2xl:w-6/12'
            richText={ourVisionTitle}
            isFirst={true}
          />
        </div>
        <div
          className='bg-white w-5/6 lg:w-1/2 mx-auto px-3 flex flex-col items-center '
          style={ourVisionDescriptionStyle}
        >
          <h2
            className='text-black text-4xl md:text-5xl lg:text-5xl font-black font-poppins 
                leading-10 mx-auto py-8 md:py-12 lg:py-12 pl-2'
            style={ourVisionTitleStyle}
          >
            {ourMissionTitle}
          </h2>
          <div
            className='m-auto w-full h-fit text-2xl md:text-3xl lg:text-3xl lg:w-4/5 text-center'
            style={ourVisionDescriptionContentStyle}
          >
            <RichTextMessage
              richText={ourMissiondescription}
              className='leading-loose'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ourVisionBannerStyle = {
  height: 'clamp(60vh, 95vh, 95vh)',
  backgroundImage: 'url("https://cdn.create.vista.com/api/media/small/37939397/stock-photo-wood-background-texture")',
  backgroundSize: 'cover'
};

const ourVisionDescriptionStyle = {
  height: 'auto',
};

const ourVisionDescriptionContentStyle = {
  font: 'normal normal normal 17px/24px proxima-nova',
  color: '#656565',
};

const ourVisionTitleStyle = {
  textShadow: '0px 3px 32px #00000029',
};

export default AboutUsheaderSection;



