import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import BlockContent from '@sanity/block-content-to-react';
import { calcRem } from '../../styles/utils';
import { Quote } from '../../svgs/quote';

const quteSeciontStyle = {
  maxWidth: calcRem(1920),
};

export function QuoteSection({ quote, author, image }) {
  const MemoizedHighlightedText = React.useCallback(
    (props) => <span className='text-dark-green'>{props.children}</span>,
    []
  );

  return (
    <div className='grid lg:grid-cols-2 mt-16 md:mt-28 lg:gap-x-20'>
      <div className='flex justify-end'>
        <div
          className='flex flex-col items-center justify-center px-16 lg:items-start'
          style={quteSeciontStyle}
        >
          <Quote className='text-green mb-4' />
          <BlockContent
            blocks={quote}
            className='font-poppins font-normal text-black text-xl text-center lg:text-3xl lg:text-left'
            renderContainerOnSingleChild
            serializers={{
              marks: {
                underline: MemoizedHighlightedText,
              },
            }}
          />
          <span className='leading-7 text-right text-lg hidden lg:block lg:self-end'>
            {author}
          </span>
        </div>
      </div>
      <div className='hidden lg:flex items-center'>
        <div
          className='flex-grow pl-4'
          style={{
            background: `linear-gradient(
              to bottom,
              transparent 0% 14%,
              #00ccad 14% 86%,
              transparent 86% 100%
            )`,
          }}
        >
          <SanityImage
            className="lg:relative lg:-left-24"
            {...image}
            width={750}
            loading="lazy"
            style={{
              width: calcRem(750),
              aspectRatio: image.asset.metadata.dimensions.aspectRatio,
            }}
            alt={`Quote illustration - ${author}`}
          />
        </div>
      </div>
    </div>
  );
}
