import React from 'react';
import { RichTextTitle } from '../../../ui-kit/RichTextTitle';
import { PostItem } from './PostsSection';

const PostsCategorie = ({
  categorieId,
  whatIsNewAtKimbocareText,
  articles,
}) => {
  let categories = extractCtegories(articles);
  let currentCategorie = {};

  categories.map((cat) => {
    if (cat.categorieId.toLowerCase() === categorieId) currentCategorie = cat;
  });
  if (!currentCategorie.categorieId) {
    currentCategorie = {
      title: whatIsNewAtKimbocareText,
      categorieId: categorieId,
    };
  }
  let postsToShow = new Array();
  postsToShow = extractPostToShow(articles, currentCategorie.categorieId);

  return (
    <div className='w-11/12 lg:w-11/12 xl:w-11/12 2xl:w-10/12 mx-auto pt-4 lg:pt-24'>
      <div className='w-full flex justify-between'>
        <RichTextTitle richText={currentCategorie.title} className=' ' />
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4 xl:pt-12'>
        {postsToShow.map((post, index) => (
          <PostItem
            key={`${index}_p`}
            post={post}
            categorie={post.categories[0].categorieId}
          />
        ))}
      </div>
    </div>
  );
};

function extractCtegories(posts) {
  const extractedCategories = new Array();
  posts.forEach((post) => {
    post.categories.map((categorie) => {
      if (
        extractedCategories.length == 0 ||
        !findInObject(extractedCategories, categorie.categorieId)
      )
        extractedCategories.push(categorie);
    });
  });
  return extractedCategories;
}

function extractPostToShow(articles, catid) {
  const extractedPosts = new Array();
  articles.forEach((art) => {
    art.categories.map((categorie) => {
      if (categorie.categorieId === catid) extractedPosts.push(art);
    });
  });
  return extractedPosts;
}

function findInObject(extractedCategories, catid) {
  let inside = false;
  extractedCategories.forEach((exc) => {
    if (exc.categorieId === catid) {
      inside = true;
      return inside;
    }
  });
  return inside;
}

export default PostsCategorie;
