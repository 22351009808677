import { upperFirst } from 'lodash';

export const locales = [{ code: 'en', isDefault: true }, { code: 'fr' }];

export const defaultLocale = locales.find(({ isDefault }) => isDefault);

const toRawLocaleCode = (localeCode) => `_raw${upperFirst(localeCode)}`;

export const createLocaleTextGetter = (localeCode) => {
  const locale = locales.find(({ code }) => code === localeCode);

  if (!locale) throw new Error('Unknown locale');

  const localeKeys = [toRawLocaleCode(locale.code), localeCode];

  if (locale.code !== defaultLocale.code)
    localeKeys.push(toRawLocaleCode(defaultLocale.code), defaultLocale.code);

  const localize = (value) => {
    if (Array.isArray(value)) {
      return value.map((v) => localize(v, localeKeys));
    }

    if (value && typeof value === 'object') {
      if (value && /^locale[A-Z]/.test(value._type)) {
        const language = localeKeys.find((lang) => value[lang]);
        return value[language];
      }

      return Object.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], localeKeys);
        return result;
      }, {});
    }

    return value;
  };

  return localize;
};
