import React, { useState, useContext } from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import RecentPosts from './recentPosts';
import { Link } from 'gatsby';
import moment from 'moment';
import { LanguageContext } from '../../../utils/context';
import './styles/postCss.css';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import CategoriesTitleDisplay from './CategoriesTitleDisplay';
import classNames from 'classnames';

const PostsSection = ({
  latestArticles,
  blogdescription,
  viewMore,
  searchArticles,
  articles,
}) => {
  const [posts, setPosts] = useState(sortPosts(articles));
  let categories = extractCtegories(posts);
  const defaultItemsPerSlides = 3.7;
  function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={classNames(
          'rounded-full flex items-center justify-center text-center movebouttons',
          className
        )}
        style={{ ...style, display: 'flex', height: '50px', width: '50px' }}
        onClick={onClick}
      />
    );
  }

  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={classNames(
          'rounded-full bg-kimbo-blue flex items-center justify-center text-center movebouttons',
          className
        )}
        style={{ ...style, display: 'flex', height: '50px', width: '50px' }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  return (
    <>
      <Helmet>
        <link
          rel='stylesheet'
          type='text/css'
          charset='UTF-8'
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
        />
        <link
          rel='stylesheet'
          type='text/css'
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
        />
      </Helmet>
      <div className='w-11/12 lg:w-10/12 xl:w-10/12 2xl:w-8/12 mx-auto flex flex-col  pt-4 lg:pt-24'>
        <div className='w-full'>
          <RecentPosts
            key='RecentPosts'
            blogdescription={blogdescription}
            latestArticles={latestArticles}
            viewMore={viewMore}
            searchArticles={searchArticles}
            posts={sortPosts(articles)}
          />
        </div>
        <div className='w-full'>
          {categories.map((cat, index) => {
            return (
              <>
                <CategoriesTitleDisplay
                  key={`${index}_c`}
                  categorieTitle={cat.title}
                  viewMore={viewMore}
                  categorieID={cat.categorieId}
                  externalCategorieLink={cat.externalCategorieLink}
                />
                <Slider
                  {...settings}
                  slidesToShow={
                    posts.filter((post) =>
                      find(post.categories, cat.categorieId)
                    ).length > 4
                      ? defaultItemsPerSlides
                      : posts.filter((post) =>
                          find(post.categories, cat.categorieId)
                        ).length
                  }
                  slidesToScroll={
                    posts.filter((post) =>
                      find(post.categories, cat.categorieId)
                    ).length > 4
                      ? defaultItemsPerSlides
                      : posts.filter((post) =>
                          find(post.categories, cat.categorieId)
                        ).length
                  }
                  responsive={[
                    {
                      breakpoint: 1300,
                      settings: {
                        slidesToShow:
                          posts.filter((post) =>
                            find(post.categories, cat.categorieId)
                          ).length > 2
                            ? 2.1
                            : posts.filter((post) =>
                                find(post.categories, cat.categorieId)
                              ).length,
                        slidesToScroll:
                          posts.filter((post) =>
                            find(post.categories, cat.categorieId)
                          ).length > 2
                            ? 2
                            : posts.filter((post) =>
                                find(post.categories, cat.categorieId)
                              ).length,
                        infinite: true,
                        dots: true,
                      },
                    },
                    {
                      breakpoint: 809,
                      settings: {
                        slidesToShow:
                          posts.filter((post) =>
                            find(post.categories, cat.categorieId)
                          ).length > 1
                            ? 1.5
                            : posts.filter((post) =>
                                find(post.categories, cat.categorieId)
                              ).length,
                        slidesToScroll:
                          posts.filter((post) =>
                            find(post.categories, cat.categorieId)
                          ).length > 1
                            ? 1
                            : posts.filter((post) =>
                                find(post.categories, cat.categorieId)
                              ).length,
                        infinite: true,
                        dots: true,
                      },
                    },
                    {
                      breakpoint: 622,
                      settings: {
                        slidesToShow:
                          posts.filter((post) =>
                            find(post.categories, cat.categorieId)
                          ).length > 1
                            ? 1.1
                            : posts.filter((post) =>
                                find(post.categories, cat.categorieId)
                              ).length,
                        slidesToScroll:
                          posts.filter((post) =>
                            find(post.categories, cat.categorieId)
                          ).length > 1
                            ? 1
                            : posts.filter((post) =>
                                find(post.categories, cat.categorieId)
                              ).length,
                        infinite: true,
                        dots: true,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                      },
                    },
                  ]}
                  className='my-12'
                >
                  {posts
                    .filter((post) => find(post.categories, cat.categorieId))
                    .map((post, index) => (
                      <PostItem
                        key={`${index}_p`}
                        post={post}
                        categorie={cat.categorieId}
                      />
                    ))}
                </Slider>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export const PostItem = ({ post, categorie }) => {
  const language = useContext(LanguageContext);
  return (
    <>
      {post.slug ? (
        <div className='article-box my-8'>
          {language === 'en' ? (
            <a href={`${post.externalblogLink}`}>
              <ItemContent
                {...post}
                language={language}
                categorie={categorie}
              />
            </a>
          ) : (
            <a href={`${post.externalblogLink}`}>
              <ItemContent
                {...post}
                language={language}
                categorie={categorie}
              />
            </a>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const ItemContent = ({
  longtitle,
  mainImage,
  publishedAt,
  language,
  categorie,
}) => {
  return (
    <>
      <div style={{ height: '260px' }}>
        <div className='article-box-img'>
          <SanityImage
            className='w-full h-full object-cover wp-post-image'
            {...mainImage}
            loading="lazy"
            alt={longtitle}
          />
        </div>
        <h3 className='title font-poppins'>{longtitle}</h3>
        <div className='date font-poppins'>
          {formatDate(publishedAt, language)}
        </div>
      </div>
      <div className='blog-category'>
        <div className='icon'>
          <svg
            width='16'
            height='16'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
          >
            <path d='M12.909 2H9.087a1.09 1.09 0 0 0-.767.315l-6 5.911a1.091 1.091 0 0 0 0 1.543l3.91 3.911a1.091 1.091 0 0 0 1.544 0l5.911-6c.202-.204.315-.48.315-.767V3.091c0-.602-.488-1.09-1.091-1.091zM11 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2z'></path>
          </svg>
        </div>
        <div className='text-cat  '>{categorie.split('-').join(' ')}</div>
      </div>
    </>
  );
};

function extractCtegories(posts) {
  const extractedCategories = new Array();
  posts.forEach((post) => {
    post.categories.map((categorie) => {
      if (
        extractedCategories.length == 0 ||
        !find(extractedCategories, categorie.categorieId)
      )
        extractedCategories.push(categorie);
    });
  });
  return extractedCategories;
}

function find(extractedCategories, catid) {
  let inside = false;
  extractedCategories.forEach((exc) => {
    if (exc.categorieId === catid) {
      inside = true;
      return inside;
    }
  });
  return inside;
}

function sortPosts(posts) {
  var changed;
  do {
    changed = false;
    for (var i = 0; i < posts.length - 1; i++) {
      if (
        moment(posts[i].publishedAt).isBefore(moment(posts[i + 1].publishedAt))
      ) {
        var tmp = posts[i];
        posts[i] = posts[i + 1];
        posts[i + 1] = tmp;
        changed = true;
      }
    }
  } while (changed);

  return posts;
}

function formatDate(string, language) {
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  return language === 'en'
    ? new Date(string).toLocaleDateString('en-US', options)
    : new Date(string).toLocaleDateString('fr-FR', options);
}

const postTitle = {
  color: '#2F2D2D',
  lineHeight: '50px',
};

export default PostsSection;
