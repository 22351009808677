import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


export const IosFrenchButton = ({isfooter}) => {
	return (
		<>
		   
			<div className={`ios-image-wrapper-fr ${isfooter?'b-b-footer':''}`}>

			</div>
			{/* <StaticImage
				src="../../static/google-play-badge-fr.png"
				alt="Google Download app image french version"
				placeholder="blurred"
				layout="fixed"
				width={250}
			/> */}
		</>
	)
}

export const IosEnglishButton = ({isfooter}) => {
	return (
		<>
			<div className={`ios-image-wrapper-en ${isfooter?'b-b-footer':''}`}>

			</div>
			{/* <StaticImage
				src="../../static/badge-app-store-en.png"
				alt="Google Download app image english version"
				placeholder="blurred"
				layout="fixed"
				width={250}
			/> */}
		</>
	)
}
