import * as React from 'react';

export function Quote(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={70} height={59} {...props}>
      <defs>
        <clipPath id="a">
          <path d="M0 0h70v59H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path fill="rgba(255,255,255,0)" d="M0 0h70v59H0z" />
        <g data-name="Groupe 9824">
          <g data-name="Groupe 9823">
            <path
              data-name="Trac\xE9 2321"
              d="M1 29.719v27.719h27.719V29.719H12.88a15.84 15.84 0 0 1 15.839-15.844V2A27.718 27.718 0 0 0 1 29.719Zm67.317-15.844V2a27.718 27.718 0 0 0-27.719 27.719v27.719h27.719V29.719h-15.84a15.84 15.84 0 0 1 15.84-15.844Z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
