import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import classNames from 'classnames';
import { calcRem } from '../../styles/utils';
import { RichTextTitle } from '../../ui-kit/RichTextTitle';
import { RichTextMessage } from '../../ui-kit/RichTextMessage';

export function BoxesSection({ title, boxes, spacing }) {
  return (
    <div className='mt-16 md:mt-28 flex flex-col items-center'>
      <RichTextTitle className='text-center px-8 w-5/6' richText={title} />
      <div
        className={classNames(
          'scrollbar p-8 lg:pt-16 flex overflow-x-auto gap-x-7 max-w-full',
          spacing === 'tight' ? 'lg:gap-x-8' : 'lg:gap-x-20'
        )}
      >
        {boxes.map((box) => (
          <Box {...box} />
        ))}
      </div>
    </div>
  );
}

const boxStyle = {
  boxShadow: `${calcRem(0)} ${calcRem(20)} ${calcRem(20)} #7682B72E`,
  borderRadius: calcRem(5),
  padding: '2.125rem',
};

function Box({ icon, title, text }) {

  return (
    <div
      className='flex-shrink-0 flex flex-col items-start bg-white w-64 lg:w-79'
      style={boxStyle}
    >
      <SanityImage
        {...icon}
        width={58}
        style={{
          height: calcRem(58),
          aspectRatio: icon.asset.metadata.dimensions.aspectRatio,
        }}
        loading="lazy"
        className='mb-8'
        alt={title}
      />
      {title && (
        <h3 className='h-14 flex items-end font-poppins font-black text-light-black text-lg mb-8'>{title[0].children[0].text}</h3>
      )}
      <p className='font-light lg:text-lg'>{text}</p>
    </div>
  );
}
