import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const Pagination = ( props ) => {
 
  let { className, pageData, pageSize, setData } = {...props}

  const classes = classNames(
    'w-fit flex gap-x-12',
    className
  )

  const numberOfElement = pageData.length
  const numberOfPage = (numberOfElement % pageSize === 0) ? numberOfElement / pageSize : Math.floor(numberOfElement / pageSize) + 1
  const [currentPage, setCurrentPage] = useState(1);
 
  const nextPage = () => {
    const offset = pageSize*currentPage
    if ((currentPage + 1) <= numberOfPage) {
      setCurrentPage(prevPage => (prevPage + 1))
      let end = offset+pageSize
      end <= numberOfElement? setData(pageData.slice(offset, end)):setData(pageData.slice(offset))
      setActiveLink(currentPage+1)
    }
  }

  const previousPage = () => {
    const maxPos = (pageSize*currentPage)-pageSize
    const minPos = maxPos-pageSize
    if ((currentPage - 1) >= 1) {
       setCurrentPage(prevPage => (prevPage - 1))
       minPos >= 0? setData(pageData.slice(minPos, maxPos)):setData(pageData.slice(0, maxPos))
       setActiveLink(currentPage-1)
    }
  };

  const goTo = (event, index) => {
    const end = pageSize*index
    const offset = end-pageSize
    setCurrentPage(index)
    setData(pageData.slice(offset, end))
    setActiveLink(event.target.getAttribute("id").split('_')[1])
  }

  const setActiveLink = (index) => {
    document.querySelectorAll('ul.pages-index li').forEach(element => {
      if (element.classList.contains("active")) 
        element.classList.remove("active");
    })
    document.getElementById("list_"+index).classList.add("active");
  }

  return (
    <>
      {
        pageSize < numberOfElement ?
          <div className="w-full flex justify-center my-20">
            <div className={classNames(classes)}>
              <div className="flex gap-x-8">
                <span className="cursor-pointer text-kimbo-blue" onClick={previousPage}>Previous</span>
                <span className="cursor-pointer text-kimbo-blue" onClick={nextPage}>Next</span>
              </div>
              <p>{currentPage} of {numberOfPage}</p>
              <div >
                <ul className="w-fit list-none flex border-b pb-1 pages-index">
                  <li id="list_1" className="mr-2.5 active cursor-pointer" onClick={(event) => goTo(event, 1)}>1</li>
                  {ramdomArray(numberOfPage).map((index) => (
                      index != pageSize? <li id={`list_${index}`} className="mr-2.5 cursor-pointer" onClick={(event) => goTo(event, index)}>{index}</li>
                      : <li id={`list_${index}`} className="cursor-pointer" onClick={(event) => goTo(event, index)}>{index}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div> : <></>
      }
    </>

  );
};

function ramdomArray(size){
  let ramdomArray = []
  for (let index = 1; index < size; index++) {
     ramdomArray.push(index+1);
  }
  return ramdomArray
}
export default Pagination;
