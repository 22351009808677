import React from 'react';
import { useForm } from 'react-hook-form';

export function SubscribeToNewsletter({
  message,
  fieldPlaceholder,
  buttonLabel,
}) {
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = (data) => console.log(data);

  return (
    <div className="bg-blue flex justify-center items-center px-8 lg:py-0">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="container flex flex-col justify-center items-center flex-grow gap-y-8 my-8 lg:flex-row lg:gap-x-8"
      >
        <span className="text-white font-poppins font-bold text-lg lg:text-xl lg:mr-20 text-center">
          {message}
        </span>
        <input
          type="email"
          placeholder={fieldPlaceholder}
          {...register('email')}
          className="rounded-full px-7 focus:outline-none text-black placeholder-black placeholder-opacity-20 h-11 w-full lg:w-96 lg:h-14"
        />
        <input
          type="submit"
          value={buttonLabel}
          className="rounded-full bg-green text-white font-poppins font-semibold text-lg lg:text-xl h-11 w-full lg:w-44 lg:h-14"
        />
      </form>
    </div>
  );
}
