import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";
import { calcRem } from "../../../styles/utils";
import { ButtonBlueShape } from "../../../ui-kit/Button";
import { RichTextMessage } from "../../../ui-kit/RichTextMessage";
import "./jobPosition.css";

const JobPositinSection = ({ title, jobImageBanner, jobs }) => {
  const jobsToShow = jobs.filter((job) => job.isActive);
  return (
    <>
      <div
        className="w-full bg-white relative jobImageBanner z-0"
        style={jobPositionBannerStyle}
      >
        <SanityImage
          className="w-full h-full object-cover"
          {...jobImageBanner}
          loading="lazy"
          alt={title}
        />
      </div>
      <div className="w-full h-fit bg-transparent -mt-40 relative z-5">
        <h3
          className="text-white font-bold w-5/6 lg:w-9/12 text-4xl md:text-5xl lg:text-5xl font-poppins leading-10 mx-auto py-4 md:py-8 lg:py-8 pl-2"
          style={jobPositionTitleStyle}
        >
          {title}
        </h3>
        <div
          className="bg-white w-5/6 lg:w-9/12  mx-auto flex items-center"
          style={jobPositionDescriptionStyle}
        >
          <div
            className="m-auto w-full h-fit text-2xl md:text-3xl lg:text-3xl lg:w-full text-center"
            style={jobPositionDescriptionContentStyle}
          >
            <section class="pt-8 lg:pt-16 pb-10 lg:pb-20 rounded-lg bg-[#F3F4F6] mx-auto">
              <div class="container">
                <div class="flex justify-around flex-wrap">
                  {jobsToShow.map((job) => (
                    <JobItem {...job} />
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

const JobItem = ({ jobtitle, jobImage, jobDescription, applyLink }) => {
  return (
    <div class="w-full md:w-1/2 xl:w-1/3 px-4 pb-8">
      <div class="bg-white rounded-lg overflow-hidden mb-10">
        <SanityImage
          className="w-full h-full object-cover hover:scale-105"
          {...jobImage}
          alt={jobtitle}
          loading="lazy"
        />
        <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
          <h3>
            <a
              href="javascript:void(0)"
              class="
                                font-semibold
                                text-dark text-xl
                                sm:text-[22px]
                                md:text-xl
                                lg:text-[22px]
                                xl:text-xl
                                2xl:text-[22px]
                                mb-4
                                block
                                hover:text-primary
                                "
            >
              {jobtitle}
            </a>
          </h3>
          <p class="text-base text-body-color leading-relaxed mb-7">
            <RichTextMessage richText={jobDescription} />
          </p>
          <ButtonBlueShape className="mx-auto mt-8 text-sm sm:text-lg md:text-sm w-7/12 md:w-6/12 lg:w-6/12">
            <Link to={applyLink}>Apply here</Link>
          </ButtonBlueShape>
        </div>
      </div>
    </div>
  );
};
const jobPositionBannerStyle = {
  height: "clamp(40vh, 50vh, 50vh)",
};

const jobPositionDescriptionStyle = {
  borderRadius: calcRem(5),
};

const jobPositionDescriptionContentStyle = {
  color: "#151D41",
};

const jobPositionTitleStyle = {
  textShadow: "0px 3px 32px #00000029",
};

export default JobPositinSection;
