import React from 'react';

export function Check(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25.81}
      height={19}
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h25.81v19H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M23.406.4a1.354 1.354 0 111.914 1.911L9.078 18.553a1.354 1.354 0 01-1.914 0L.4 11.785a1.354 1.354 0 111.911-1.914l5.81 5.81z"
          fill="currentColor"
          data-name="Icon/Check"
        />
      </g>
    </svg>
  );
}
