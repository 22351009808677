import React from 'react'
import SanityImage from 'gatsby-plugin-sanity-image'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'

import { RichTextTitle } from '../../ui-kit/RichTextTitle'
import { calcRem } from '../../styles/utils'
import { RichTextMessage } from '../../ui-kit/RichTextMessage'

export function TheyTrustKimboCareSection({ title, organizations, trophy }) {
  const StyledSlider = styled(Slider)`
    .slick-slide:first-child,
    .slick-slide:last-child {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
  `

  const settings = {
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 6,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
        },
      },
    ],
  }

  return (
    <div className='px-8 mt-5'>
      <RichTextTitle richText={title} className='text-center' />
      <StyledSlider
        {...settings}
        className='justify-center my-4 gap-4 md:gap-12 md:mt-24 md:mb-16'
      >
        {organizations.map(({ name, logo }) => (
          <Organization key={name} name={name} logo={logo} />
        ))}
      </StyledSlider>
      <Trophy {...trophy} />
    </div>
  )
}

function Organization({ name, logo }) {
  const { width, height } = logo.asset.metadata.dimensions

  return (
    <div className='flex justify-center items-center'>
      <SanityImage
        {...logo}
        width={width}
        height={height}
        loading="lazy"
        alt={name}
        style={{ width, height: 'auto' }}
      />
    </div>
  )
}

function Trophy({ image, message }) {
  return (
    <div className='flex flex-col items-center gap-y-5 mt-16'>
      <div>
        <SanityImage
          {...image}
          alt='Trophy image'
          style={{
            aspectRatio: image.asset.metadata.dimensions.aspectRatio,
            height: calcRem(70),
          }}
        />
      </div>
      <RichTextMessage className='md:text-lg text-center' richText={message} />
    </div>
  )
}
