/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { graphql, withPrefix } from "gatsby";
import { Helmet } from "react-helmet";

import classNames from "classnames";
import { Layout } from "../components/Layout";
import { Footer } from "../components/footer/Footer";
import { SubscribeToNewsletter } from "../components/footer/SubscribeToNewsLetter";
import { Sections } from "../components/sections/Sections";
import { Menu } from "../components/menu/Menu";
import { localize } from "../components/localize";
import { defaultLocale, locales } from "../utils/i18n";
import * as PageStyles from "./Page.module.css";
import { LanguageContext } from "../utils/context";

function Page({ pageContext, data }) {
  const { locale, location } = pageContext;
  const {
    title,
    description,
    background,
    withAppButtonLink,
    menu,
    body: sections,
    footer,
  } = data.sanityPage;

  const [language, setLanguage] = useState(locale);

  return (
    <LanguageContext.Provider value={language}>
      <Layout
        className={classNames({ [PageStyles.bgWave]: background === "wave" })}
      >
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-TQLS2N8"
            title="googletagmanager"
            height="0"
            width="0"
            style={gtagStyle}
          />
        </noscript>

        <Helmet title={title} htmlAttributes={{ lang: language }}>
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.kimbocare.com" />
          <meta
            property="og:image"
            content="https://th.bing.com/th/id/R.521936020e16a855e5d508fe1e5939ac?rik=OZ5XkTcdlTUneQ&pid=ImgRaw&r=0"
          />
          <meta property="og:description" content={description} />
          <meta name="description" content={description} />
          <meta
            name="google-site-verification"
            content="5jt2Mp5mjnNJq9yd5yUBa1sagf162eruvEbNJYaP2qg"
          />
          <script
            defer
            src="https://www.googletagmanager.com/gtag/js?id=G-29Y88WSHTB"
          />
          <script
            src={withPrefix("google-tag-script.js")}
            defer
            type="text/javascript"
          />
          <script
            defer
            src="https://tools.luckyorange.com/core/lo.js?site-id=eb9dfaca"
          />

          <script
            defer
            src="https://platform.illow.io/banner.js?siteId=cb216fe5-088b-4002-9e7e-34c52cc37d20"
          />
        </Helmet>
        {renderMenu(language, location, menu)}
        {pageContext.slug || pageContext.categorieId ? (
          pageContext.slug ? (
            <Sections
              slug={pageContext.slug}
              sections={sections}
              pageContext={pageContext}
            />
          ) : (
            <Sections
              categorieId={pageContext.categorieId}
              sections={sections}
              pageContext={pageContext}
            />
          )
        ) : pageContext.videos ? (
          <Sections
            videos={pageContext.videos}
            sections={sections}
            pageContext={pageContext}
          />
        ) : (
          <Sections sections={sections} pageContext={pageContext} />
        )}
        {renderFooter(language, footer, background, withAppButtonLink)}
      </Layout>
    </LanguageContext.Provider>
  );
}

export default localize(Page);

export const pageQuery = graphql`
  fragment LocaleString on SanityLocaleString {
    _type
    en
    fr
  }

  fragment LocaleText on SanityLocaleText {
    _type
    en: _rawEn
    fr: _rawFr
  }

  fragment LocaleBlockContent on SanityBlockContent {
    _type
    en: _rawEn
    fr: _rawFr
  }

  fragment LocaleTitle on SanityLocaleTitle {
    _type
    en: _rawEn
    fr: _rawFr
  }

  fragment ImageWithPreviewAndMetadata on SanityImage {
    ...ImageWithPreview
    asset {
      metadata {
        dimensions {
          _key
          _type
          height
          width
          aspectRatio
        }
      }
    }
  }

  fragment LocaleImage on SanityLocaleImage {
    _type
    en {
      ...ImageWithPreviewAndMetadata
    }
    fr {
      ...ImageWithPreviewAndMetadata
    }
  }

  query Page($id: String!) {
    sanityPage(internalId: { eq: $id }) {
      internalId
      title {
        ...LocaleString
      }
      description {
        ...LocaleString
      }
      background
      menu {
        privatePageButtonLabel {
          ...LocaleString
        }
        corporatePageButtonLabel {
          ...LocaleString
        }
        links {
          _key
          label {
            ...LocaleString
          }
          url
        }
        signUpButtonLabel {
          ...LocaleString
        }
        beAKimboButtonLabel {
          ...LocaleString
        }
      }
      body {
        __typename
        ... on SanityCorporateHeader {
          _id
          title {
            ...LocaleTitle
          }
          text {
            ...LocaleText
          }
          buttonLabel {
            ...LocaleString
          }
          image {
            ...LocaleImage
          }
        }
        ... on SanityPrivateHeader {
          _id
          title {
            ...LocaleTitle
          }
          text {
            ...LocaleText
          }
          buttonLabel {
            ...LocaleString
          }
          steps {
            _key
            title {
              ...LocaleString
            }
            text {
              ...LocaleString
            }
            image {
              ...LocaleImage
            }
            completedIcon {
              ...ImageWithPreviewAndMetadata
            }
            uncompletedIcon {
              ...ImageWithPreviewAndMetadata
            }
          }
        }
        ... on SanityTrustpilotSection {
          _id
          title {
            ...LocaleTitle
          }
          templateId
          businessUnitId
        }
        ... on SanityBoxesSection {
          _id
          spacing
          title {
            ...LocaleTitle
          }
          boxes {
            _key
            text {
              ...LocaleString
            }
            title {
              ...LocaleText
            }
            icon {
              ...ImageWithPreviewAndMetadata
            }
          }
        }
        ... on SanityImpactMapSection {
          _id
          map {
            ...ImageWithPreviewAndMetadata
          }
          legendItems {
            _key
            color {
              hex
            }
            label {
              ...LocaleString
            }
          }
        }
        ... on SanityBulletedListSection {
          _id
          title {
            ...LocaleTitle
          }
          items {
            ...LocaleString
          }
          image {
            ...LocaleImage
          }
          imagePosition
        }
        ... on SanityTheyTrustKimboCareSection {
          _id
          title {
            ...LocaleTitle
          }
          organizations {
            name
            logo {
              ...ImageWithPreviewAndMetadata
            }
          }
          trophy {
            image {
              ...ImageWithPreviewAndMetadata
            }
            message {
              ...LocaleText
            }
          }
        }
        ... on SanityQuoteSection {
          _id
          quote {
            ...LocaleTitle
          }
          author
          buttonLabel {
            ...LocaleString
          }
          image {
            ...LocaleImage
          }
        }
        ... on SanityButtonsSection {
          _id
          primaryButton {
            description {
              ...LocaleString
            }
            descriptionBusiness {
              ...LocaleString
            }
            label {
              ...LocaleString
            }
            labelBusiness {
              ...LocaleString
            }
          }
          secondaryButton {
            description {
              ...LocaleString
            }
            descriptionBusiness {
              ...LocaleString
            }
            label {
              ...LocaleString
            }
            labelBusiness {
              ...LocaleString
            }
          }
        }
        ... on SanityStepsSection {
          _id
          title {
            ...LocaleTitle
          }
          step {
            _key
            label {
              ...LocaleString
            }
            title {
              ...LocaleString
            }
            description {
              ...LocaleText
            }
            image {
              ...LocaleImage
            }
          }
        }
        ... on SanityAboutUsheaderSection {
          banner {
            ...LocaleImage
          }
          ourMissionTitle {
            ...LocaleString
          }
          ourMissiondescription {
            ...LocaleText
          }
          ourVisionTitle {
            ...LocaleTitle
          }
          title {
            ...LocaleTitle
          }
        }
        ... on SanityWhyCreatedKimbocareSection {
          title {
            ...LocaleTitle
          }
          description {
            ...LocaleText
          }
        }
        ... on SanityWhereWeservePatientsSection {
          description {
            ...LocaleText
          }
          referenceBanner {
            ...LocaleImage
          }
          title {
            ...LocaleTitle
          }
        }
        ... on SanityHolisticApproachSection {
          title {
            ...LocaleTitle
          }
          description {
            ...LocaleText
          }
        }
        ... on SanityHolisticApproachSection {
          title {
            ...LocaleTitle
          }
          description {
            ...LocaleText
          }
        }
        ... on SanityBoxesAvantagesSection {
          boxes {
            icon {
              ...ImageWithPreviewAndMetadata
            }
            items {
              ...LocaleString
            }
            boxtitle {
              ...LocaleString
            }
          }
          title {
            ...LocaleTitle
          }
        }
        ... on SanityDownloadTheAppSection {
          _id
          title {
            ...LocaleTitle
          }
          text {
            ...LocaleText
          }
          buttonLabel {
            ...LocaleString
          }
          image {
            ...ImageWithPreviewAndMetadata
            ...ImageWithPreview
          }
        }
        ... on SanityOurTeamSection {
          _id
          oTtitle {
            ...LocaleString
          }
          teamMembers {
            _key
            description {
              ...LocaleText
            }
            memberAvatar {
              ...ImageWithPreview
            }
            memberName
            memeberRole {
              ...LocaleString
            }
            socialNetworks {
              msocialNetworkIcon {
                ...ImageWithPreview
              }
              msocialNetworkName
              msocilaNetworkLink
            }
          }
        }
        ... on SanityHealthcareProvidersSection {
          _id
          hptitle {
            ...LocaleString
          }
          healthcareProviders {
            _key
            availableModules {
              ...LocaleText
            }
            openingHour {
              ...LocaleText
            }
            owner {
              ...LocaleString
            }
            providerImage {
              ...ImageWithPreview
            }
            providerLocation {
              ...LocaleString
            }
            providerName {
              ...LocaleString
            }
          }
          loadMoreButtonText {
            ...LocaleString
          }
        }
        ... on SanityCallToActionNetworkSection {
          _id
          cntitle {
            ...LocaleString
          }
          advantages {
            _key
            advantageDescription {
              ...LocaleText
            }
            advantageWording {
              ...LocaleString
            }
          }
          callToActionButtonText {
            ...LocaleString
          }
        }
        ... on SanityPost {
          _id
          artitle
          latestArticles {
            ...LocaleTitle
          }
          blogdescription {
            ...LocaleText
          }
          mostRecentText {
            ...LocaleString
          }
          whatIsNewAtKimbocareText {
            ...LocaleTitle
          }
          viewMore {
            ...LocaleString
          }
          searchArticles {
            ...LocaleString
          }
          ReadMoreButton {
            ...LocaleString
          }
          articles {
            longtitle {
              ...LocaleString
            }
            slug {
              current
            }
            externalblogLink {
              ...LocaleString
            }
            authorpost {
              name
            }
            mainImage {
              ...ImageWithPreviewAndMetadata
            }
            imageCaption {
              ...LocaleString
            }
            categories {
              categorieId
              externalCategorieLink{
                ...LocaleString
              }
              title {
                ...LocaleTitle
              }
            }
            publishedAt
            body {
              ...LocaleText
            }
          }
        }
        ... on SanityHelp {
          _id
          hTtitle {
            ...LocaleString
          }
          description {
            ...LocaleText
          }
          questionsAnswers {
            question {
              ...LocaleString
            }
            answer {
              ...LocaleText
            }
          }
        }
        ... on SanityVideoSection {
          _id
          pagetitle {
            ...LocaleTitle
          }
          pageDescription {
            ...LocaleText
          }
          newTag {
            ...LocaleString
          }
          videos {
            title
            isEvent
            videoType
            videoId
            videoPublishedDate
          }
        }
        ... on SanityJobSection {
          _id
          jobImageBanner {
            ...ImageWithPreview
          }
          jobs {
            isActive
            jobtitle {
              ...LocaleString
            }
            jobImage {
              ...ImageWithPreview
            }
            jobDescription {
              ...LocaleText
            }
            applyLink
          }
          jtitle {
            ...LocaleString
          }
        }
      }
      footer {
        __typename
        ... on SanityPageFooter {
          _id
          columns {
            __typename
            ... on SanityContactColumn {
              _key
              title {
                ...LocaleString
              }
              address
              email
            }
            ... on SanityLinksColumn {
              _key
              title {
                ...LocaleString
              }
              links {
                _key
                label {
                  ...LocaleString
                }
                url
              }
            }
            ... on SanitySocialNetworksColumn {
              _key
              title {
                ...LocaleString
              }
              links {
                logo {
                  ...ImageWithPreviewAndMetadata
                }
                url
              }
            }
          }
        }
        ... on SanitySubscribeToNewsletter {
          _id
          message {
            ...LocaleString
          }
          fieldPlaceholder
          buttonLabel {
            ...LocaleString
          }
        }
      }
      background
      withAppButtonLink
    }
  }
`;

function renderMenu(locale, location, menu) {
  const {
    privatePageButtonLabel,
    corporatePageButtonLabel,
    links,
    signUpButtonLabel,
    beAKimboButtonLabel,
  } = menu;
  const isDefaultLocaleActive = locale === defaultLocale.code;
  const localePathPrefix = isDefaultLocaleActive ? "" : `/${locale}`;
  let activePathWithoutLocale = location || "";
  if (!isDefaultLocaleActive)
    activePathWithoutLocale = (location || "").replace(locale, "");
  const isPrivatePageActive = activePathWithoutLocale === "/";

  const getLocalizedLinkToActivePage = (code) => {
    const isDefaultLocale = code === defaultLocale.code;

    return isDefaultLocale
      ? activePathWithoutLocale
      : `/${code}${activePathWithoutLocale}`;
  };

  return (
    <Menu
      privateButton={{
        label: privatePageButtonLabel,
        active: isPrivatePageActive,
        pagePath: `${localePathPrefix}/`,
      }}
      corporateButton={{
        label: corporatePageButtonLabel,
        active: !isPrivatePageActive,
        pagePath: `${localePathPrefix}/corporate`,
      }}
      links={links.map((link) => ({
        label: link.label,
        pagePath: `${localePathPrefix}${link.url}`,
      }))}
      localeChoices={locales.map(({ code }) => ({
        code,
        active: code === locale,
        linkTo: getLocalizedLinkToActivePage(code),
      }))}
      signUpButtonLabel={signUpButtonLabel}
      beAKimboButtonLabel={beAKimboButtonLabel}
      isPrivatePageActive={isPrivatePageActive}
    />
  );
}

function renderFooter(locale, footer, background, withAppButtonLink) {
  const isDefaultLocaleActive = locale === defaultLocale.code;
  const localePathPrefix = isDefaultLocaleActive ? "" : `/${locale}`;
  const renderFooterSection = ({ _id, columns }) => {
    const props = {
      key: _id,
      columns: columns.map(({ _key, __typename, title, ...column }) => {
        const columnProps = {
          key: _key,
          type: __typename,
          title,
        };

        switch (columnProps.type) {
          case "SanityContactColumn":
            return {
              ...columnProps,
              address: column.address,
              email: column.email,
            };
          case "SanityLinksColumn":
            return {
              ...columnProps,
              links: column.links.map((link) => ({
                label: link.label,
                url: `${localePathPrefix}${link.url}`,
              })),
            };
          case "SanitySocialNetworksColumn":
            return {
              ...columnProps,
              links: column.links,
            };
          default:
            throw new Error(`Unknown column type [${columnProps.type}]`);
        }
      }),
    };

    return (
      <Footer
        background={background}
        withAppButtonLink={withAppButtonLink}
        {...props}
      />
    );
  };

  const renderSubscribeToNewsletter = ({
    _id,
    message,
    fieldPlaceholder,
    buttonLabel,
  }) => (
    <SubscribeToNewsletter
      key={_id}
      message={message}
      fieldPlaceholder={fieldPlaceholder}
      buttonLabel={buttonLabel}
    />
  );

  return footer.map(({ __typename, ...section }) => {
    switch (__typename) {
      case "SanityPageFooter":
        return renderFooterSection(section);
      case "SanitySubscribeToNewsletter":
        return renderSubscribeToNewsletter(section);
      default:
        throw new Error(`Unknown footer section[${__typename}]`);
    }
  });
}

const gtagStyle = {
  display: "none",
  visibility: "hidden",
};
