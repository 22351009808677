import React from 'react'
import { calcRem } from '../../styles/utils'
import { RichTextTitle } from '../../ui-kit/RichTextTitle'

const corporateHeaderStyle = {
  maxWidth: calcRem(1920),
}

let loadScript = function (src) {
  let tag = document.createElement('script')
  tag.async = false
  tag.src = src
  document.getElementsByTagName('body')[0].appendChild(tag)
}
export class TrustpilotSection extends React.Component {
  componentDidMount() {
    if (window) {
      loadScript('https://cdn.trustindex.io/loader.js')
    }
  }

  render() {
    return (
      <div className='mx-auto mt-5 px-8 lg:px-16' style={corporateHeaderStyle}>
        <div className='flex flex-col lg:flex-row items-center lg:py-28 lg:gap-x-14'>
          <RichTextTitle
            richText={this.props.title}
            className='text-center lg:text-left lg:w-5/12 bg-light-gray px-16 py-32'
            fontSize={'text-6xl'}
          />
          <div className='flex-grow mt-12 lg:mt-12 w-full lg:w-6/12'>
            <div
              src='https://cdn.trustindex.io/loader.js?50761352985c144ff9365bcfbda'
              className='w-full'
            ></div>
          </div>
        </div>
      </div>
    )
  }
}

// export function TrustpilotSection({
//   title
// }) {

//   return (
//     <>
//       <Helmet>
//          <script type="text/javascript" src='https://cdn.trustindex.io/loader.js'></script>
//       </Helmet>
//       <div className="mx-auto mt-28 lg:mt-0" style={trustpilotSectionStyle}>
//         <div className="lg:bg-light-gray flex flex-col lg:flex-row items-center px-8 lg:py-28 lg:gap-x-14 lg:mx-32">
//           <RichTextTitle
//             richText={title}
//             className="text-center lg:text-left lg:w-5/12"
//           />
//           <div
//             className="flex-grow mt-12 lg:mt-12 w-full lg:w-6/12"
//           >
//             <div src='https://cdn.trustindex.io/loader.js?fe2772b587a45804d15f6fb3f7' className="w-full"></div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
