import React from 'react';

import { createLocaleTextGetter } from '../utils/i18n';

export function localize(Component) {
  return class Localize extends React.Component {
    constructor(props) {
      super(props);

      this.getLocalizedContent = createLocaleTextGetter(
        props.pageContext.locale
      );
    }

    render() {
      const { data, ...props } = this.props;

      return <Component {...props} data={this.getLocalizedContent(data)} />;
    }
  };
}
