import React, { useContext } from 'react';
import { IosFrenchButton, IosEnglishButton } from '../../svgs/IosSvgButtons';
import './IosAndroidButton.css';
import { LanguageContext } from '../../utils/context';
import { AndroidEnglishButton, AndroidFrenchButton } from '../../svgs/AndroidSvgButtons';

const detect_OS_set_HTML_class = () => {
    if (typeof document !== `undefined`) {
        const html = document.documentElement;
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return "Windows";
        }
        if (/android/i.test(userAgent)) {
            html.className += " os_Android";
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            html.className += " os_iOS";
            return "iOS";
        }
        return "Unknown";
    }
};

const IosAndroidButton = ({ androidAppLink, iosAppLink, isFooter }) => {
    detect_OS_set_HTML_class();
    const language = useContext(LanguageContext);

    const iosButton = language === "en" ? (
        <>
            <IosEnglishButton className="dwnld_img-apple" isFooter={isFooter} aria-hidden="true" />
            <span className="sr-only">Apple app: Download from the App Store</span>
        </>
    ) : (
        <>
            <IosFrenchButton className="dwnld_img-apple" isFooter={isFooter} aria-hidden="true" />
            <span className="sr-only">Apple app : Télécharger depuis l'App Store</span>
        </>
    );

    const androidButton = language === "en" ? (
        <>
            <AndroidEnglishButton className="dwnld_img-google" isFooter={isFooter} aria-hidden="true" />
            <span className="sr-only">Android app: Get it on Google Play</span>
        </>
    ) : (
        <>
            <AndroidFrenchButton className="dwnld_img-google" isFooter={isFooter} aria-hidden="true" />
            <span className="sr-only">Android app : Télécharger sur Google Play</span>
        </>
    );

    return (
        <div>
            <section className={`btns_cnt-downloads flex justify-center ${isFooter ? 'xl:justify-around flex-col xl:flex-row' : 'md:justify-around lg:justify-around xl:justify-around flex-col md:flex-row lg:flex-row xl:flex-row'} items-center text-center lg:text-left`}>
                <a className="dwnld_lnk-apple cursor-pointer" target="_blank" rel="noopener noreferrer" href={iosAppLink || 'https://apps.apple.com/fr/app/kimbocare-mobile/id1617607005?l=en'} aria-label={language === "en" ? "Apple app: Download from the App Store" : "Apple app : Télécharger depuis l'App Store"}>
                    {iosButton}
                </a>
                <a className="dwnld_lnk-android mt-4 xl:mt-0 xl:ml-4 cursor-pointer" target="_blank" rel="noopener noreferrer" href={androidAppLink || 'dwnld_lnk-android mt-4 xl:mt-0 xl:ml-4 cursor-pointer'} aria-label={language === "en" ? "Android app: Get it on Google Play" : "Android app : Télécharger sur Google Play"}>
                    {androidButton}
                </a>
            </section>
        </div>
    );
};

export default IosAndroidButton;
