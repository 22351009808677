import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { RichTextMessage } from '../../ui-kit/RichTextMessage';
import { RichTextTitle } from '../../ui-kit/RichTextTitle';
import IosAndroidButton from '../../ui-kit/IosAndroidButton/IosAndroidButton';
import { calcRem } from '../../styles/utils';

const corporateHeaderStyle = {
  maxWidth: calcRem(1920),
};

export function DownloadTheAppSection({ title, text, buttonLabel, image }) {
  return (
    <div
      className='grid lg:grid-cols-2 mt-10 md:mt-16 lg:gap-x-40 -mb-16 md:-mb-28 mx-auto lg:px-16 pb-8 lg:pb-0'
      style={corporateHeaderStyle}
    >
      <div className='flex justify-center lg:justify-start'>
        <div className='flex flex-col items-center justify-center px-8 lg:px-0 lg:items-start lg:max-w-2xl gap-y-12'>
          <RichTextTitle
            className='text-center lg:text-left'
            richText={title}
          />
          <RichTextMessage
            className='text-center text-2xl lg:text-left'
            richText={text}
          />
          <IosAndroidButton
            androidappLink='https://play.google.com/store/apps/details?id=com.kimbocare.kimboCareMobile'
            iosAppLink='https://apps.apple.com/fr/app/kimbocare-mobile/id1617607005?l=en'
          />
        </div>
      </div>

      <div className='hidden lg:flex items-center'>
        <div className='flex-grow lg:pl-10'>
          <SanityImage
            {...image}
            loading="lazy"
            style={{
              aspectRatio: image.asset.metadata.dimensions.aspectRatio,
            }}
            alt={title}
          />
        </div>
      </div>
    </div>
  );
}
