import SanityImage from 'gatsby-plugin-sanity-image';
import React, { useEffect, useRef } from 'react';
import { calcRem } from '../../styles/utils';

export function ImpactsMapSection({ map, legendItems }) {
  const worldMapContainerRef = useRef();

  useEffect(() => {
    const worldMapContainer = worldMapContainerRef.current;
    const worldMap = worldMapContainerRef.current.children[0];

    worldMapContainer.scrollLeft =
      worldMap.offsetLeft +
      worldMap.offsetWidth / 2 -
      worldMapContainer.offsetWidth / 2;
  }, []);

  return (
    <div className="container mx-auto mt-16 md:mt-28 flex flex-col items-center">
      <div
        ref={worldMapContainerRef}
        className="scrollbar overflow-x-auto max-w-full"
      >
        <SanityImage
          {...map}
          alt="Impacts world map"
          loading="lazy"
          style={{
            width: '100%',
            minWidth: calcRem(900),
            aspectRatio: map.asset.metadata.dimensions.aspectRatio,
          }}
        />
      </div>
      <div className="flex flex-col justify-center gap-y-6 md:flex-row">
        {legendItems.map(({ key, color, label }) => (
          <LegendItem key={key} color={color} label={label} />
        ))}
      </div>
    </div>
  );
}

function LegendItem({ color, label }) {
  return (
    <div className="flex items-center mx-10 md:justify-center">
      <div
        className="w-7 h-7 rounded-full mr-3"
        style={{ backgroundColor: color }}
      />
      {label}
    </div>
  );
}
