import classNames from 'classnames';
import React, { useState } from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { calcRem } from '../../../styles/utils';
import { RichTextTitle } from '../../../ui-kit/RichTextTitle';
import { RichTextMessage } from '../../../ui-kit/RichTextMessage';
import { Button } from '../../../ui-kit/Button';

import * as privateHeaderSectionStyles from './PrivateHeaderSection.module.css';

const privateHeaderStyle = {
  maxWidth: calcRem(1920),
};

export function PrivateHeaderSection({ title, text, buttonLabel, steps }) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const onStepChanged = (newIndex) => setCurrentStepIndex(newIndex);

  return (
    <div
      className='mx-auto xl:gap-x-14 pt-4 xl:pt-0 xl:px-16 -mt-16 md:-mt-40 xl:-mt-8'
      style={privateHeaderStyle}
    >
      <div className='flex flex-col xl:flex-row xl:relative'>
        <Carousel
          className='xl:w-2/3 flex-grow xl:order-1'
          activeIndex={currentStepIndex}
          onStepChanged={onStepChanged}
        >
          {steps.map(
            (
              { image, title: stepTitle, text: stepText, completedIcon },
              index
            ) => (
              <CarouselItem key={index} className='flex flex-col xl:relative'>
                <SanityImage
                  {...image}
                  alt={title}
                  style={{
                    width: '100%',
                    aspectRatio: image.asset.metadata.dimensions.aspectRatio,
                  }}
                  className='xl:absolute xl:top-0 xl:left-0'
                  loading="lazy"
                />
                <div className='xl:hidden flex flex-col items-center bg-light-gray font-poppins text-black w-4/5 p-4 mt-[-3.75rem] whitespace-normal text-center gap-y-2'>
                  <div className='w-12 h-12 flex justify-center items-center rounded-full bg-green mt-[-40px]'>
                    <SanityImage
                      {...completedIcon}
                      width={24}
                      style={{
                        width: calcRem(24),
                        aspectRatio:
                          completedIcon.asset.metadata.dimensions.aspectRatio,
                      }}
                      loading="lazy"
                      alt={stepTitle}
                    />
                  </div>
                  <div className='font-black text-sm leading-6 flex-1 flex-shrink'>
                    {stepTitle}
                  </div>
                  <div className='text-sm leading-4'>{stepText}</div>
                  <div className='flex justify-center gap-x-4'>
                    <CarouselButton
                      className={classNames({
                        hidden: currentStepIndex <= 0,
                      })}
                      onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                    >
                      &lt;
                    </CarouselButton>
                    <CarouselButton
                      className={classNames({
                        hidden: currentStepIndex >= steps.length - 1,
                      })}
                      onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                    >
                      &gt;
                    </CarouselButton>
                  </div>
                </div>
              </CarouselItem>
            )
          )}
        </Carousel>
        <div className='mt-6 xl:mt-20 px-8 xl:px-0 xl:w-1/3 flex-grow flex flex-col justify-center'>
          <RichTextTitle
            richText={title}
            className='mb-3 md:mb-8 lg:mb-12 xl:mb-16 text-center xl:text-left'
            isFirst={true}
          />
          {text.map((paragraph) => (
            <RichTextMessage
              key={paragraph._key}
              richText={paragraph}
              className='my-2 text-lg xl:mr-8 text-center xl:text-left'
            />
          ))}
          <div className='flex justify-center xl:justify-start pt-6 md:mb-8 lg:mb-8 lg:pt-12'>
            <Button href='https://app.kimbocare.com' target='_blank'>
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
      <div className='hidden bg-light-gray xl:flex justify-around p-8'>
        {steps.map(
          (
            {
              title: stepTitle,
              text: stepText,
              completedIcon,
              uncompletedIcon,
            },
            index
          ) => {
            const hasPreviousStep = index > 0;
            const hasNextStep = index < steps.length - 1;
            const isOver = index <= currentStepIndex;

            return (
              <div className='flex-grow' key={index}>
                <div className='flex justify-center items-center'>
                  <div
                    className={classNames(
                      'flex-grow',
                      hasPreviousStep ? 'h-0.25' : 'h-0',
                      isOver ? 'bg-green' : 'bg-gray-2'
                    )}
                  />
                  <div
                    className={classNames(
                      'w-21 h-21 flex justify-center items-center rounded-full',
                      'bg-green'
                    )}
                  >
                    <SanityImage
                      {...completedIcon}
                      width={completedIcon.asset.metadata.dimensions.width}
                      height={completedIcon.asset.metadata.dimensions.height}
                      style={{
                        width: calcRem(
                          completedIcon.asset.metadata.dimensions.width
                        ),
                        height: calcRem(
                          completedIcon.asset.metadata.dimensions.height
                        ),
                      }}
                      loading="lazy"
                      alt={stepTitle}
                    />
                  </div>
                  <div
                    className={classNames(
                      'flex-grow',
                      hasNextStep ? 'h-0.25' : 'h-0',
                      index < currentStepIndex ? 'bg-green' : 'bg-gray-2'
                    )}
                  />
                </div>
                <div className='text-center px-4 flex flex-col items-center justify-center font-poppins text-black'>
                  <h2 className='w-60 font-black text-xl leading-6 mt-3'>
                    {stepTitle}
                  </h2>
                  <div className='w-60 font-medium text-lg leading-6 mt-3'>
                    {stepText}
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

export function Carousel({ className, children, activeIndex, onStepChanged }) {
  const boundIndex = (unboundedIndex) => {
    if (unboundedIndex < 0) {
      return 0;
    }

    if (unboundedIndex >= React.Children.count(children)) {
      return React.Children.count(children) - 1;
    }

    return unboundedIndex;
  };

  const updateIndex = (newIndex) => {
    const boundedNewIndex = boundIndex(newIndex);

    if (boundedNewIndex !== activeIndex) {
      onStepChanged(boundedNewIndex);
    }
  };

  const index = boundIndex(activeIndex);

  return (
    <div
      className={classNames(
        privateHeaderSectionStyles.carousel,
        'xl:h-[40.625rem]',
        className
      )}
    >
      <div
        className={privateHeaderSectionStyles.carouselInner}
        style={{ transform: `translateX(-${index * 100}%)` }}
      >
        {children}
      </div>
      {/* <div className='hidden xl:flex xl:justify-center xl:absolute xl:bottom-3 xl:left-0 xl:right-0 gap-x-3'>
        <CarouselButton onClick={() => updateIndex(index - 1)}>
          &lt;
        </CarouselButton>
        <CarouselButton onClick={() => updateIndex(index + 1)}>
          &gt;
        </CarouselButton>
      </div> */}
    </div>
  );
}

export function CarouselItem({ children, className }) {
  return (
    <div
      className={classNames(
        privateHeaderSectionStyles.carouselItem,
        'w-full',
        className
      )}
    >
      {children}
    </div>
  );
}

function CarouselButton({ children, className, onClick }) {
  return (
    <button
      type='button'
      className={classNames(
        className,
        'bg-white rounded-full w-8 h-8 xl:w-12 xl:h-12 flex justify-center items-center'
      )}
      onClick={onClick}
      style={{ boxShadow: '0px 10px 20px #7682B72E' }}
    >
      <p className='text-2xl text-light-gray-2'>{children}</p>
    </button>
  );
}
