import SanityImage from "gatsby-plugin-sanity-image";
import React, { useContext } from "react";
import { RichTextMessage } from "../../../ui-kit/RichTextMessage";
import "./styles/blog.css";
import ShareButtons from "../../../ui-kit/shareButtons/sharebuttons";
import { LanguageContext } from "../../../utils/context";
import { PonctualRecentPostsWithSearch } from "./recentPosts";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

const SinglePost = ({
  slug,
  latestArticles,
  searchArticles,
  mostRecentText,
  articles,
}) => {
  const currentPost = articles.filter(
    (article) => article.slug.current == slug
  )[0];
  return (
    <div className="w-11/12 lg:w-10/12 mx-auto lg:grid lg:grid-cols-8 lg:gap-24 pt-4 lg:pt-24">
      <div className="lg:col-span-5">
        <SinglePostItem {...currentPost} />
      </div>
      <div className="lg:col-span-3 w-full">
        <PonctualRecentPostsWithSearch
          key="RecentPosts"
          {...currentPost}
          searchArticles={searchArticles}
          mostRecentText={mostRecentText}
          posts={articles}
        />
      </div>
    </div>
  );
};

const SinglePostItem = ({
  longtitle,
  slug,
  authorpost,
  categories,
  imageCaption,
  body,
  mainImage,
  publishedAt,
}) => {

  const language = useContext(LanguageContext);
  let title = "";
  if (language === "en") title = `Read ${longtitle}`;
  else if (language === "fr") title = `Lire ${longtitle}`;
  const tags = categories;
  let url = "";
  if (typeof window !== "undefined") url = window.location.href;
  const twitterHandle = authorpost.name;
  return (
    <>
      <div className="flex flex-col gap-2">
        {language == "en" ? (
          <Link to={`/blog`}>
            <span
              className="text-lg text-start text-green tracking-wider md:text-lg h-14 md:h-19 
                    flex bg-transparent font-poppins font-semibold"
            >
              &#8592; Back to blog
            </span>
          </Link>
        ) : (
          <Link to={`/fr/blog`}>
            <span
              href="https://www.kimbocare.com/fr/blog"
              className="text-lg text-start text-green tracking-wider md:text-lg h-14 md:h-19 
                    flex bg-transparent font-poppins font-semibold"
            >
              &#8592; Retour au blog
            </span>
          </Link>
        )}
      </div>
      <Helmet title={longtitle}>
        <meta name="description" content={longtitle} />
        <meta property="og:title" content={longtitle} />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={publishedAt} />
        <meta property="article:author" content={authorpost.name} />
        <meta
          property="article:section"
          content={categories[0].categorieId.split("-").join(" ")}
        />
        <meta
          property="og:url"
          content={`https://www.kimbocare.com/blog/${slug.current}`}
        />
        <meta property="og:image" content={mainImage.asset.metadata.preview} />
        <meta property="og:description" content={longtitle} />
      </Helmet>
      <div className="mb-24 postt-wrapper">
        <div className="flex flex-col gap-2">
          <h1
            className="w-full lg:w-11/12 text-3xl md:text-3xl lg:text-4xl font-black font-poppins leading-6 lg:leading-8"
            style={postTitle}
          >
            {longtitle}
          </h1>
          <div className="w-full flex flex-row py-4">
            <span
              className="font-poppins text-xs text-center flex items-center md:text-xl lg:text-xl xl:text-xl md:py-4 lg:py-4 xl:py-4 border-r pr-8  "
              style={postGInfo}
            >
              {formatDate(publishedAt, language)}
            </span>
            <span
              className="font-poppins text-xs text-center flex items-center md:text-xl lg:text-xl xl:text-xl md:py-4 lg:py-4 xl:py-4 border-r px-8  "
              style={postGInfo}
            >
              <a href={`${categories[0].externalCategorieLink}`}>{categories[0].categorieId.split("-").join(" ")}</a>
            </span>
            <span
              className="font-poppins text-xs text-center flex items-center md:text-xl lg:text-xl xl:text-xl md:py-4 lg:py-4 xl:py-4 px-8  "
              style={postGInfo}
            >
              {authorpost.name}
            </span>
          </div>
        </div>
        <div className="mb-4 flex flex-col">
          <SanityImage
            className="w-full lg:rounded-md"
            {...mainImage}
            alt="blog post main image"
            loading="lazy"
            style={{ maxHeight: "420px", objectFit: "cover" }}
          />
          <span className="font-poppins py-4" style={postImageCaption}>
            {imageCaption}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <ShareButtons
            title={title}
            url={url}
            twitterHandle={twitterHandle}
            tags={tags}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div id="postContent">
            <RichTextMessage richText={body} usePortableText={true} />
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-6">
          <ShareButtons
            title={title}
            url={url}
            twitterHandle={twitterHandle}
            tags={tags}
          />
        </div>
        <div className="flex flex-col gap-2">
          {language == "en" ? (
            <Link to={`/blog`}>
              <span
                className="py-16 text-lg text-start text-green tracking-wider md:text-lg h-14 md:h-19 
                    flex bg-transparent font-poppins font-semibold"
              >
                &#8592; Back to blog
              </span>
            </Link>
          ) : (
            <Link to={`/fr/blog`}>
              <span
                href="https://www.kimbocare.com/fr/blog"
                className="py-16 text-lg text-start text-green tracking-wider md:text-lg h-14 md:h-19 
                    flex bg-transparent font-poppins font-semibold"
              >
                &#8592; Retour au blog
              </span>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

function formatDate(string, language) {
  let options = { year: "numeric", month: "long", day: "numeric" };
  return language === "en"
    ? new Date(string).toLocaleDateString("en-US", options)
    : new Date(string).toLocaleDateString("fr-FR", options);
}

const postTitle = {
  color: "#2F2D2D",
  lineHeight: "50px",
};

const postGInfo = {
  color: "#020102",
};

const postImageCaption = {
  color: "#949494",
  font: "normal normal bold 14px/16px Poppins",
};

export default SinglePost;
