import SanityImage from "gatsby-plugin-sanity-image";
import React, { useState } from "react";
import { calcRem } from "../../styles/utils";
import { RichTextMessage } from "../../ui-kit/RichTextMessage";
import { RichTextTitle } from "../../ui-kit/RichTextTitle";
import RequestDemoDialog from "../../ui-kit/RequestDemoModal/RequestDemoModal";

const corporateHeaderStyle = {
  maxWidth: calcRem(1920),
};

export function CorporateHeaderSection({ title, text, buttonLabel, image }) {
  const [isGetStartedOpen, setIsGetStartedOpen] = useState(false);
  return (
    <div
      className="mx-auto px-8 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-14 pt-8 lg:pt-32 lg:px-32"
      style={corporateHeaderStyle}
    >
      <div className="order-1 lg:order-2">
        <SanityImage
          {...image}
          alt={title}
          style={{
            width: "100%",
            aspectRatio: image.asset.metadata.dimensions.aspectRatio,
          }}
          config={{ quality: 100 }}
          loading="eager"
        />
      </div>
      <div className="order-2 lg:order-1">
        <RichTextTitle
          richText={title}
          className="text-center lg:mb-8 lg:text-left"
          isFirst={true}
        />
        {text.map((paragraph) => (
          <RichTextMessage
            key={paragraph._key}
            richText={paragraph}
            className="my-4 lg:mt-4 lg:mb-4 text-lg lg:mr-8 text-center lg:text-left"
          />
        ))}
        <div className="flex justify-center lg:justify-start pt-6 lg:pt-12 md:mb-8 lg:mb-8">
          <button
            style={{
              ...(true && {
                boxShadow: `${calcRem(0)} ${calcRem(10)} ${calcRem(
                  30
                )} #00A8935C`,
              }),
            }}
            className="px-11 text-lg text-center tracking-wider md:text-2xl h-14 md:h-19 flex justify-center items-center bg-transparent rounded-full font-poppins font-semibold bg-kimbo-gradient bg-no-repeat bg-origin-padding bg-clip-padding cursor-pointer text-white"
            onClick={() => setIsGetStartedOpen(true)}
          >
            {buttonLabel}
          </button>
          {isGetStartedOpen && (
            <RequestDemoDialog setIsOpen={setIsGetStartedOpen} />
          )}
        </div>
      </div>
    </div>
  );
}