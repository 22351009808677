import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import './androidSvg.css'

export const AndroidFrenchButton = ({isfooter}) => {
	return (
		<>
			<div className={`an-image-wrapper-fr ${isfooter?'b-b-footer':''}`}>

			</div>
			{/* <StaticImage
				src="../../static/google-play-badge-fr.png"
				alt="Google Download app image french version"
				placeholder="blurred"
				layout="fixed"
				width={250}
			/> */}
		</>
	)
}

export const AndroidEnglishButton = ({isfooter}) => {
	return (
		<>
			<div className={`an-image-wrapper-en ${isfooter?'b-b-footer':''}`}>

			</div>
			{/* <StaticImage
				src="../../static/google-play-badge-en.png"
				alt="Google Download app image english version"
				placeholder="blurred"
				layout="fixed"
				width={250}
			/> */}
		</>
	)
}
